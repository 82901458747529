<template>
  <div>
    <b-row class="content-header">
      <b-col class="content-header-left mb-2" cols="12" md="9">
        <b-row class="breadcrumbs-top">
          <b-col cols="10">
            <h2 class="content-header-title float-left pr-1 mb-0">Devices</h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  v-for="item in breadcrumbs"
                  :key="item.text"
                  :active="item.active"
                  :to="item.to"
                >
                  {{ item.text }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="3" class="text-right">
        <div class="d-flex justify-content-between">
          <div>
            <b-button block variant="primary" to="/device/add">
              <span class="text-nowrap"
                >Add Device <feather-icon icon="PlusIcon"></feather-icon>
              </span>
            </b-button>
          </div>
          <div>
            <b-button block variant="outline-primary" to="/bulk-upload/models">
              <span class="text-nowrap"
                >Bulk Upload <feather-icon icon="FileTextIcon"></feather-icon>
              </span>
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3" cols="6" v-for="model in models" :key="model._id">
        <b-link :to="`/brand/${brand}/${model.slug}`">
          <b-card class="">
            <div class="text-center">
              <div class="model-logo">
                <img
                  class="w-100"
                  :src="model.media.image"
                  :alt="model.media.alt"
                />
              </div>
              {{ model.name }}
            </div>
          </b-card>
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { getUserToken } from "@/auth/utils";
import axios from "axios";
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BBreadcrumb,
  BBreadcrumbItem,
  BButton,
} from "bootstrap-vue";

export default {
  data() {
    return {
      models: [],
      brand: null,
      breadcrumbs: [
        {
          text: "Dashboard",
          to: "/dashboard",
        },
        {
          text: "Brands",
          to: "/brand",
        },
      ],
      brand: null,
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    BBreadcrumb,
    BBreadcrumbItem,
    BButton,
  },
  methods: {
    getModels() {
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/brand/models/${this.$route.params.brand}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.breadcrumbs.push({
            text: response.data.brand.name,
            active: true,
          });
          this.models = response.data.models;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
  },
  mounted() {
    this.brand = this.$route.params.brand;
    this.getModels();
  },
};
</script>

<style>
.model-logo {
  max-width: 80px;
  margin: 0 auto;
}
</style>
